/**
 * Generated by orval v6.25.0 🍺
 * Do not edit manually.
 * Delivery User API
 * Draft version
 * OpenAPI spec version: 24.11.06
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  ApiErrorResponseResponse,
  AuthAnonymousRequestBodyBody,
  AuthAnonymousResponseResponse,
  GetHealthHeaders,
  GetOrdersHeaders,
  GetOrdersParams,
  GetUserMeHeaders,
  HealthResponseResponse,
  OrderCreateRequestBodyBody,
  OrderRefuseRequestBodyBody,
  OrderResponseResponse,
  OrdersListWithCursorResponseResponse,
  PostAuthAnonymousHeaders,
  PostOrderCreateHeaders,
  PostOrderRefuseHeaders,
  PostUserMeUpdateHeaders,
  UserMeResponseResponse,
  UserMeUpdateRequestBodyBody
} from './types'
import { authInstance } from './axios';
import type { ErrorType } from './axios';




/**
 * Для получения временной анонимной авторизации
 * @summary Анонимная авторизация
 */
export const postAuthAnonymous = (
    authAnonymousRequestBodyBody: AuthAnonymousRequestBodyBody,
    headers?: PostAuthAnonymousHeaders,
 ) => {
      
      
      return authInstance<AuthAnonymousResponseResponse>(
      {url: `/auth/anonymous`, method: 'POST',
      headers: {'Content-Type': 'application/json', ...headers},
      data: authAnonymousRequestBodyBody
    },
      );
    }
  


export const getPostAuthAnonymousMutationOptions = <TError = ErrorType<ApiErrorResponseResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAuthAnonymous>>, TError,{data: AuthAnonymousRequestBodyBody;headers?: PostAuthAnonymousHeaders}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postAuthAnonymous>>, TError,{data: AuthAnonymousRequestBodyBody;headers?: PostAuthAnonymousHeaders}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postAuthAnonymous>>, {data: AuthAnonymousRequestBodyBody;headers?: PostAuthAnonymousHeaders}> = (props) => {
          const {data,headers} = props ?? {};

          return  postAuthAnonymous(data,headers,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostAuthAnonymousMutationResult = NonNullable<Awaited<ReturnType<typeof postAuthAnonymous>>>
    export type PostAuthAnonymousMutationBody = AuthAnonymousRequestBodyBody
    export type PostAuthAnonymousMutationError = ErrorType<ApiErrorResponseResponse>

    /**
 * @summary Анонимная авторизация
 */
export const usePostAuthAnonymous = <TError = ErrorType<ApiErrorResponseResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAuthAnonymous>>, TError,{data: AuthAnonymousRequestBodyBody;headers?: PostAuthAnonymousHeaders}, TContext>, }
) => {

      const mutationOptions = getPostAuthAnonymousMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Информация о текущем пользователе
 * @summary Текущий пользователь
 */
export const getUserMe = (
    headers?: GetUserMeHeaders,
 signal?: AbortSignal
) => {
      
      
      return authInstance<UserMeResponseResponse>(
      {url: `/users/me`, method: 'GET',
      headers, signal
    },
      );
    }
  

export const getGetUserMeQueryKey = () => {
    return [`/users/me`] as const;
    }

    
export const getGetUserMeQueryOptions = <TData = Awaited<ReturnType<typeof getUserMe>>, TError = ErrorType<ApiErrorResponseResponse>>(headers?: GetUserMeHeaders, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUserMe>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUserMeQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserMe>>> = ({ signal }) => getUserMe(headers, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getUserMe>>, TError, TData> & { queryKey: QueryKey }
}

export type GetUserMeQueryResult = NonNullable<Awaited<ReturnType<typeof getUserMe>>>
export type GetUserMeQueryError = ErrorType<ApiErrorResponseResponse>

/**
 * @summary Текущий пользователь
 */
export const useGetUserMe = <TData = Awaited<ReturnType<typeof getUserMe>>, TError = ErrorType<ApiErrorResponseResponse>>(
 headers?: GetUserMeHeaders, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUserMe>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUserMeQueryOptions(headers,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Обновление данных текущего пользователя
 * @summary Обновить данные текущего пользователя
 */
export const postUserMeUpdate = (
    userMeUpdateRequestBodyBody: UserMeUpdateRequestBodyBody,
    headers?: PostUserMeUpdateHeaders,
 ) => {
      
      
      return authInstance<void>(
      {url: `/users/me`, method: 'POST',
      headers: {'Content-Type': 'application/json', ...headers},
      data: userMeUpdateRequestBodyBody
    },
      );
    }
  


export const getPostUserMeUpdateMutationOptions = <TError = ErrorType<ApiErrorResponseResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postUserMeUpdate>>, TError,{data: UserMeUpdateRequestBodyBody;headers?: PostUserMeUpdateHeaders}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postUserMeUpdate>>, TError,{data: UserMeUpdateRequestBodyBody;headers?: PostUserMeUpdateHeaders}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postUserMeUpdate>>, {data: UserMeUpdateRequestBodyBody;headers?: PostUserMeUpdateHeaders}> = (props) => {
          const {data,headers} = props ?? {};

          return  postUserMeUpdate(data,headers,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostUserMeUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof postUserMeUpdate>>>
    export type PostUserMeUpdateMutationBody = UserMeUpdateRequestBodyBody
    export type PostUserMeUpdateMutationError = ErrorType<ApiErrorResponseResponse>

    /**
 * @summary Обновить данные текущего пользователя
 */
export const usePostUserMeUpdate = <TError = ErrorType<ApiErrorResponseResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postUserMeUpdate>>, TError,{data: UserMeUpdateRequestBodyBody;headers?: PostUserMeUpdateHeaders}, TContext>, }
) => {

      const mutationOptions = getPostUserMeUpdateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Для создания нового заказа с ключем идемпотентности, если передан уже использованный ключ то возвращается статус заказа по ключу
 * @summary Размещение нового заказа
 */
export const postOrderCreate = (
    orderCreateRequestBodyBody: OrderCreateRequestBodyBody,
    headers?: PostOrderCreateHeaders,
 ) => {
      
      
      return authInstance<OrderResponseResponse>(
      {url: `/orders`, method: 'POST',
      headers: {'Content-Type': 'application/json', ...headers},
      data: orderCreateRequestBodyBody
    },
      );
    }
  


export const getPostOrderCreateMutationOptions = <TError = ErrorType<ApiErrorResponseResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postOrderCreate>>, TError,{data: OrderCreateRequestBodyBody;headers?: PostOrderCreateHeaders}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postOrderCreate>>, TError,{data: OrderCreateRequestBodyBody;headers?: PostOrderCreateHeaders}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postOrderCreate>>, {data: OrderCreateRequestBodyBody;headers?: PostOrderCreateHeaders}> = (props) => {
          const {data,headers} = props ?? {};

          return  postOrderCreate(data,headers,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostOrderCreateMutationResult = NonNullable<Awaited<ReturnType<typeof postOrderCreate>>>
    export type PostOrderCreateMutationBody = OrderCreateRequestBodyBody
    export type PostOrderCreateMutationError = ErrorType<ApiErrorResponseResponse>

    /**
 * @summary Размещение нового заказа
 */
export const usePostOrderCreate = <TError = ErrorType<ApiErrorResponseResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postOrderCreate>>, TError,{data: OrderCreateRequestBodyBody;headers?: PostOrderCreateHeaders}, TContext>, }
) => {

      const mutationOptions = getPostOrderCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Для получения списка заказов с возможностью порционного получения данных в режиме только вперед
 * @summary Список заказов
 */
export const getOrders = (
    params?: GetOrdersParams,
    headers?: GetOrdersHeaders,
 signal?: AbortSignal
) => {
      
      
      return authInstance<OrdersListWithCursorResponseResponse>(
      {url: `/orders`, method: 'GET',
      headers,
        params, signal
    },
      );
    }
  

export const getGetOrdersQueryKey = (params?: GetOrdersParams,) => {
    return [`/orders`, ...(params ? [params]: [])] as const;
    }

    
export const getGetOrdersQueryOptions = <TData = Awaited<ReturnType<typeof getOrders>>, TError = ErrorType<ApiErrorResponseResponse>>(params?: GetOrdersParams,
    headers?: GetOrdersHeaders, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOrders>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOrdersQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrders>>> = ({ signal }) => getOrders(params,headers, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getOrders>>, TError, TData> & { queryKey: QueryKey }
}

export type GetOrdersQueryResult = NonNullable<Awaited<ReturnType<typeof getOrders>>>
export type GetOrdersQueryError = ErrorType<ApiErrorResponseResponse>

/**
 * @summary Список заказов
 */
export const useGetOrders = <TData = Awaited<ReturnType<typeof getOrders>>, TError = ErrorType<ApiErrorResponseResponse>>(
 params?: GetOrdersParams,
    headers?: GetOrdersHeaders, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOrders>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOrdersQueryOptions(params,headers,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Для размещения запроса на отказ от заказа. Если нет заказа с таким идентификатором, то в ответе 404 код с ошибкой notFound.
 * @summary Размещение запроса на отказ от заказа
 */
export const postOrderRefuse = (
    orderRefuseRequestBodyBody: OrderRefuseRequestBodyBody,
    headers?: PostOrderRefuseHeaders,
 ) => {
      
      
      return authInstance<void>(
      {url: `/orders/refuse`, method: 'POST',
      headers: {'Content-Type': 'application/json', ...headers},
      data: orderRefuseRequestBodyBody
    },
      );
    }
  


export const getPostOrderRefuseMutationOptions = <TError = ErrorType<ApiErrorResponseResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postOrderRefuse>>, TError,{data: OrderRefuseRequestBodyBody;headers?: PostOrderRefuseHeaders}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postOrderRefuse>>, TError,{data: OrderRefuseRequestBodyBody;headers?: PostOrderRefuseHeaders}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postOrderRefuse>>, {data: OrderRefuseRequestBodyBody;headers?: PostOrderRefuseHeaders}> = (props) => {
          const {data,headers} = props ?? {};

          return  postOrderRefuse(data,headers,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostOrderRefuseMutationResult = NonNullable<Awaited<ReturnType<typeof postOrderRefuse>>>
    export type PostOrderRefuseMutationBody = OrderRefuseRequestBodyBody
    export type PostOrderRefuseMutationError = ErrorType<ApiErrorResponseResponse>

    /**
 * @summary Размещение запроса на отказ от заказа
 */
export const usePostOrderRefuse = <TError = ErrorType<ApiErrorResponseResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postOrderRefuse>>, TError,{data: OrderRefuseRequestBodyBody;headers?: PostOrderRefuseHeaders}, TContext>, }
) => {

      const mutationOptions = getPostOrderRefuseMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Для получения статуса работоспособности API
 * @summary Статус работоспособности API
 */
export const getHealth = (
    headers?: GetHealthHeaders,
 signal?: AbortSignal
) => {
      
      
      return authInstance<HealthResponseResponse>(
      {url: `/health`, method: 'GET',
      headers, signal
    },
      );
    }
  

export const getGetHealthQueryKey = () => {
    return [`/health`] as const;
    }

    
export const getGetHealthQueryOptions = <TData = Awaited<ReturnType<typeof getHealth>>, TError = ErrorType<unknown>>(headers?: GetHealthHeaders, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getHealth>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetHealthQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getHealth>>> = ({ signal }) => getHealth(headers, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getHealth>>, TError, TData> & { queryKey: QueryKey }
}

export type GetHealthQueryResult = NonNullable<Awaited<ReturnType<typeof getHealth>>>
export type GetHealthQueryError = ErrorType<unknown>

/**
 * @summary Статус работоспособности API
 */
export const useGetHealth = <TData = Awaited<ReturnType<typeof getHealth>>, TError = ErrorType<unknown>>(
 headers?: GetHealthHeaders, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getHealth>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetHealthQueryOptions(headers,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




